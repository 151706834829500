import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Row,
  Horizontal,
  Column,
  Text,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { TotalizerButtons } from './TotalizerButtons'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Cookies,
  eventClick,
  formatSubtotal,
  getCurrentDomain,
  isValidArrayWithData,
  trigger,
  useEvents,
  useMobile,
  useNewCartUnimarc,
  useOrderForm,
  useSimulationsForPayments
} from '@smu-chile/pkg-unimarc-hooks'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { TooltipWrapper } from '../TooltipWrapper'
import { SubMenu } from 'components/SubMenu/SubMenu'
import { ERROR_MESSAGES } from 'components/Body/helper/constants'
import { TotalUnipay } from './TotalUnipay'
import { UNIPAY_ID } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { IGetMyUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'

export interface TotalizersProps {
  createListButton: boolean
  isLoadingByEvent: boolean
  limitOnProductAlert: boolean
  notificationAddToList: boolean
  openMyUsuals: boolean
  selectedListName: string
  handleGetMyUsualsAndFavorites: (
    page?: number
  ) => Promise<IGetMyUsualsAndFavoritesData>
  handleOnClosed: () => void
  handleOpenMyListsModal: () => void
  setLimitOnProductAlert: (value: boolean) => void
  setNotificationAddToList: (type: boolean) => void
}

export const Totalizers = ({
  createListButton,
  isLoadingByEvent,
  limitOnProductAlert,
  notificationAddToList,
  openMyUsuals,
  selectedListName,
  handleGetMyUsualsAndFavorites,
  handleOnClosed,
  handleOpenMyListsModal,
  setLimitOnProductAlert,
  setNotificationAddToList
}: TotalizersProps): React.ReactElement => {
  const domain = getCurrentDomain()
  const { data, isLoading, isFetching } = useNewCartUnimarc()
  const { data: queryOrderForm, isLoading: isLoadingQueryOrderForm } =
    useOrderForm()
  const {
    data: simulationData,
    refetch: refetchSimulation,
    isFetching: isFetchingSimulation
  } = useSimulationsForPayments({
    payments: [UNIPAY_ID]
  })
  const [showTooltipError, setShowTooltipError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [codeError, setCodeError] = useState('')
  const [alcoholLaw, setAlcoholLaw] = useState(false)
  const [toastMessage, setToastMessage] = useState({
    show: false,
    message: ''
  })
  const [showMyUsuals, setShowMyUsuals] = useState(false)
  const [minValueToBuy, setMinValueToBuy] = useState<string | number>()
  const [tooltip, setTooltip] = useState<boolean>(false)

  const orderForm: ICart = data
  const minimumPurchase = queryOrderForm?.data?.summary?.minValue
  const customHeightContainer = showMyUsuals ? '77px' : 'auto'
  const contentTooltip = useRef<HTMLDivElement>()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const _isLoading = Boolean(
    !data || isLoading || isLoadingByEvent || !orderForm?.subtotal
  )
  const disableSubTotals = _isLoading
    ? getGlobalStyle('--color-primary-disabled')
    : getGlobalStyle('--color-base-black')
  const showTooltip =
    alcoholLaw ||
    toastMessage.show ||
    notificationAddToList ||
    limitOnProductAlert ||
    showTooltipError

  useEvents({
    eventType: 'myUsuals',
    callBack: ({ detail: { show } }) => {
      setShowMyUsuals(show)
    }
  })
  useEvents({
    eventType: 'productNoteNotification',
    callBack: ({ detail: { show, txt } }) => {
      setToastMessage({
        show,
        message: txt
      })
      addAnimationAndCloseTooltip()
    }
  })
  useEvents({
    eventType: 'updateItemQuantity',
    callBack: ({ detail: { error, errorCode } }) => {
      if (error === true) {
        setShowTooltipError(error)
        setErrorMessage(ERROR_MESSAGES.errorChangingQuantity)
        setCodeError(errorCode)
      }
    }
  })

  useEvents({
    eventType: 'clearCart',
    callBack: ({ detail: { error } }) => {
      if (error === true) {
        setShowTooltipError(error)
        setErrorMessage(ERROR_MESSAGES.errorCleaningCart)
      }
    }
  })

  useEvents({
    eventType: 'updateQuantityDone',
    callBack: () => {
      refetchSimulation()
    }
  })

  const setClassAnimation = () => {
    contentTooltip.current?.classList?.add('animateTooltip')
  }

  const addAnimationAndCloseTooltip = () => {
    setTimeout(() => {
      setClassAnimation()
    }, 3000)
    Cookies.remove('showAddressChangedTooltip', {
      domain,
      path: '/'
    })
    setTimeout(() => {
      setAlcoholLaw(false)
      setLimitOnProductAlert(false)
      setNotificationAddToList(false)
      setShowTooltipError(false)
      setToastMessage({ show: false, message: '' })
    }, 4000)
  }

  const closeAlcoholLaw = () => {
    setClassAnimation()
    Cookies.remove('showAddressChangedTooltip', {
      domain,
      path: '/'
    })
    setTimeout(() => {
      setAlcoholLaw(false)
      setToastMessage({ show: false, message: '' })
    }, 1000)
  }

  const closeLimitOnProductAlert = () => {
    setClassAnimation()
    setTimeout(() => {
      setLimitOnProductAlert(false)
    }, 1000)
  }

  const closeTooltipError = () => {
    setClassAnimation()
    setTimeout(() => {
      setShowTooltipError(false)
    }, 1000)
  }

  const handleOpen = () => {
    trigger({ eventType: 'clearCart', data: { show: true } })
    eventClick({
      event: 'interacciones_carrito',
      eventAction: 'navegacion_compra',
      eventCategory: 'carrito',
      eventLabel: 'vaciar carro'
    })
  }

  useEffect(() => {
    if (orderForm?.needIdentification) {
      setAlcoholLaw(true)
      addAnimationAndCloseTooltip()
    }
  }, [orderForm?.needIdentification])

  useEffect(() => {
    if (!isLoadingQueryOrderForm && !isFetching && minimumPurchase) {
      const newSubtotal = formatSubtotal(orderForm?.subtotal).toString()
      const minimumPurchaseFormated = formatSubtotal(minimumPurchase).toString()
      setMinValueToBuy(minimumPurchase)
      parseInt(newSubtotal) <= parseInt(minimumPurchaseFormated)
        ? setTooltip(true)
        : setTooltip(false)
    }
  }, [
    isFetching,
    isLoadingQueryOrderForm,
    minimumPurchase,
    queryOrderForm?.data?.summary?.minValue
  ])

  useEffect(() => {
    if (!isLoading && !isLoadingQueryOrderForm) {
      const showTooltip = JSON.parse(
        Cookies.get('showAddressChangedTooltip') || 'false'
      )
      setToastMessage({
        show: showTooltip,
        message: 'Tu dirección de envío fue actualizada.'
      })
    }
  }, [isLoading, isLoadingQueryOrderForm])

  return (
    <>
      <BigScreen>
        <Container
          id={getBemId('totalizers', 'container')}
          isWrap
          margin={showTooltip ? '-14px 0 0 0' : '0'}
          position='sticky'
          top='32'
        >
          <Row justifyContent='start'>
            {showTooltip && !openMyUsuals && (
              <Column
                justifyContent='end'
                maxWidth='max-content'
                minHeight='70px'
                ref={contentTooltip}
              >
                <TooltipWrapper
                  alcoholLaw={alcoholLaw}
                  closeAlcoholLaw={closeAlcoholLaw}
                  closeLimitOnProductAlert={closeLimitOnProductAlert}
                  closeTooltipError={closeTooltipError}
                  codeError={codeError}
                  createListButton={createListButton}
                  errorMessage={errorMessage}
                  handleOnClosed={handleOnClosed}
                  isMobile={isMobile}
                  limitOnProductAlert={limitOnProductAlert}
                  notificationAddToList={notificationAddToList}
                  selectedListName={selectedListName}
                  setLimitOnProductAlert={setLimitOnProductAlert}
                  setNotificationAddToList={setNotificationAddToList}
                  showTooltipError={showTooltipError}
                  toastMessage={toastMessage}
                />
              </Column>
            )}
          </Row>
          {showTooltip && <Spacer.Horizontal size={12} />}
          <Row>
            <Column
              maxHeight='188px'
              maxWidth='377px'
              minHeight='188px'
              minWidth='377px'
              position='sticky'
              top='110'
            >
              <Row
                backgroundColor={getGlobalStyle('--color-base-white')}
                border={`0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-divider'
                )}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                isWrap
                padding='24px'
                tagName='aside'
              >
                <Row
                  id={getBemId('subtotal', 'container')}
                  justifyContent='between'
                >
                  <Text
                    customColor={disableSubTotals}
                    fontWeight='semibold'
                    id={getBemId('subtotal', 'text')}
                  >
                    Subtotal
                  </Text>
                  <Text
                    customColor={disableSubTotals}
                    fontWeight='semibold'
                    id={getBemId('subtotalPrice', 'text')}
                  >
                    {orderForm?.subtotal}
                  </Text>
                </Row>
                {simulationData &&
                  isValidArrayWithData(simulationData?.simulations) &&
                  !isLoadingQueryOrderForm &&
                  !_isLoading &&
                  !isFetchingSimulation && (
                    <TotalUnipay simulationData={simulationData} />
                  )}
                {tooltip && (
                  <>
                    <Spacer.Horizontal size={18} />
                    <Row
                      alignItems='center'
                      backgroundColor='#FFE5E5'
                      borderRadius='8px'
                      id={getBemId('minimumPrice', 'container')}
                      maxWidth='100%'
                      padding='8px 10px'
                      position='relative'
                    >
                      <Text
                        color='red'
                        fontSize='md'
                        fontWeight='medium'
                        id={getBemId('minimumPrice', 'text')}
                      >
                        {`🛒  Monto mínimo de compra `}
                      </Text>
                      <Spacer.Vertical size={4} />
                      <Text
                        color='red'
                        fontSize='md'
                        fontWeight='semibold'
                        id={getBemId('minimumPrice', 'price')}
                      >
                        ${minValueToBuy}
                      </Text>
                    </Row>
                    <Spacer.Horizontal size={18} />
                  </>
                )}

                {!tooltip && <Spacer.Horizontal size={18} />}
                <TotalizerButtons
                  handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
                  isFetchingSimulation={isFetchingSimulation}
                  isLoading={_isLoading}
                  isLoadingByEvent={isLoadingByEvent}
                  isLoadingQueryOrderForm={isLoadingQueryOrderForm}
                  simulationData={simulationData}
                />
              </Row>
              <Column>
                <Spacer.Horizontal customSize={21} />
                <SubMenu
                  clearCart={handleOpen}
                  disabledButtons={_isLoading}
                  saveList={handleOpenMyListsModal}
                />
              </Column>
            </Column>
          </Row>
        </Container>
      </BigScreen>

      <SmallScreen>
        <>
          {showTooltip && !openMyUsuals && (
            <Column
              margin='5px 0 0 0'
              position='fixed'
              ref={contentTooltip}
              zIndex={getGlobalStyle('--z-index-99999')}
            >
              <TooltipWrapper
                alcoholLaw={alcoholLaw}
                closeAlcoholLaw={closeAlcoholLaw}
                closeLimitOnProductAlert={closeLimitOnProductAlert}
                closeTooltipError={closeTooltipError}
                codeError={codeError}
                createListButton={createListButton}
                errorMessage={errorMessage}
                handleOnClosed={handleOnClosed}
                isMobile={isMobile}
                limitOnProductAlert={limitOnProductAlert}
                notificationAddToList={notificationAddToList}
                selectedListName={selectedListName}
                setLimitOnProductAlert={setLimitOnProductAlert}
                setNotificationAddToList={setNotificationAddToList}
                showTooltipError={showTooltipError}
                toastMessage={toastMessage}
              />
            </Column>
          )}
          {!showMyUsuals && (
            <Container
              absoluteDefault='bottomLeft'
              backgroundColor={getGlobalStyle('--color-base-white')}
              id={getBemId('totalizers', 'container')}
              isWrap
              padding='24px'
              position='fixed'
              zIndex={getGlobalStyle('--z-index-5')}
            >
              <Container
                absoluteDefault='bottomLeft'
                backgroundColor={getGlobalStyle('--color-base-white')}
                borderRadius='16px 16px 0 0'
                boxShadow={getGlobalStyle('--box-shadow-xs')}
                customHeight={customHeightContainer}
                isWrap
                padding='24px'
                position='fixed'
              >
                <Container
                  absoluteDefault='bottomLeft'
                  backgroundColor={getGlobalStyle('--color-base-white')}
                  borderRadius='12px 12px 0 0'
                  boxShadow='4px -6px 18px 0px rgba(0, 0, 0, 0.05)'
                  customHeight={customHeightContainer}
                  isWrap
                  padding='20px 16px'
                  position='fixed'
                >
                  <TotalizerButtons
                    handleGetMyUsualsAndFavorites={
                      handleGetMyUsualsAndFavorites
                    }
                    isLoading={_isLoading}
                    isLoadingByEvent={isLoadingByEvent}
                    isLoadingQueryOrderForm={isLoadingQueryOrderForm}
                    minValueToBuy={minValueToBuy}
                    showMyUsuals={showMyUsuals}
                    simulationData={simulationData}
                    subtotal={orderForm?.subtotal}
                    tooltip={tooltip}
                  />
                </Container>
              </Container>
              <Horizontal size={16} />
            </Container>
          )}
        </>
      </SmallScreen>
    </>
  )
}
