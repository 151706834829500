import {
  MobileModalWrapper,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalProps } from '../ModalDesktop'
import { ModalBody } from '../ModalBody'
import { ModalButton } from '../ModalButton'
import styles from '../MyUsualsAndFavoritesWrapper.module.css'

export const ModalMobile = ({
  favorites,
  isLoadingMyUsualsAndFavorites,
  isLoadingOrderForm,
  myUsuals,
  handleChancePage,
  handleClick,
  handleClose
}: ModalProps): React.ReactElement => {
  return (
    <MobileModalWrapper
      blockId={getBemId('myUsualsAndFavoritesModal', 'container')}
      body={
        <ModalBody
          favorites={favorites}
          handleChancePage={handleChancePage}
          isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
          isLoadingOrderForm={isLoadingOrderForm}
          isMobile={true}
          myUsuals={myUsuals}
        />
      }
      bodyContainerProps={{
        padding: '0'
      }}
      customFooter={
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          customClassName={styles.customBorderTop}
          justifyContent='center'
          padding='16px'
          zIndex={getGlobalStyle('--z-index-5')}
        >
          <ModalButton
            handleOnClick={handleClick}
            isLoadingOrderForm={isLoadingOrderForm}
            minWidth='100%'
          />
        </Container>
      }
      dragProps={{
        draggButton: false
      }}
      footerContainerProps={{
        padding: '0'
      }}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        customSize: 20
      }}
      headerProps={{
        padding: '0 16px 20px',
        minHeight: '36px',
        customClassName: styles.customHeader
      }}
      headerTitle='¡Qué no se te olviden!'
      hideHeaderDivider={false}
      iconColor={getGlobalStyle('--color-base-black')}
      iconSize={20}
      isEnableButton
      modalConfigsProps={{
        openAnimation: true,
        fullScreenSticky: true,
        hiddenX: true,
        isAutoHeight: true,
        isOpen: true,
        isWindowBlocked: true,
        marginFullScreen: '1vh 0 0',
        stickyBottom: true,
        toggle: handleClose,
        toggleOutside: handleClose
      }}
      onClose={handleClose}
      styleProps={{
        padding: '0',
        height: '100%',
        borderRadius: `${getGlobalStyle('--border-radius-md')} ${getGlobalStyle(
          '--border-radius-md'
        )} 0 0`
      }}
    />
  )
}
