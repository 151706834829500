import {
  isValidArrayWithData,
  useSimulationsForPayments
} from '@smu-chile/pkg-unimarc-hooks'
import { getCategoryFromProducts } from 'shared/helpers'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { SwiperWraper } from 'components/Swiper/SwiperWraper'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { UNIPAY_ID } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

interface ShelvesProps {
  cartData: ICart
  isLoadingByEvent: boolean
  setLimitOnProductAlert: (value: boolean) => void
}

export const Shelves = ({
  cartData,
  isLoadingByEvent,
  setLimitOnProductAlert
}: ShelvesProps): React.ReactElement => {
  const orderForm: ICart = cartData
  const products = getCategoryFromProducts(orderForm)
  const isProducts = isValidArrayWithData(products?.items)
  const { data: simulationData, isFetching: isFetchingSimulation } =
    useSimulationsForPayments({
      payments: [UNIPAY_ID]
    })

  return (
    <>
      {/* A component that uses Suspense-based */}
      {isProducts &&
        products.items.map((item, index) => {
          if (!item?.description?.productId) return null
          const productDiscount =
            isValidArrayWithData(simulationData?.simulations) &&
            simulationData?.simulations?.[0]?.items.find((product) => {
              return product?.id === item?.description?.id
            })
          return (
            <SwiperWraper
              id={getBemId('shelves', item?.description?.id, index.toString())}
              isLoading={isLoadingByEvent || isFetchingSimulation}
              isLoadingByEvent={isLoadingByEvent}
              isProducts={isProducts}
              item={item}
              key={`${item?.description?.id}_shelf${item?.description?.productId}_${item?.price?.sellingPrice}`}
              productDiscount={productDiscount}
              products={products}
              setLimitOnProductAlert={setLimitOnProductAlert}
            />
          )
        })}
    </>
  )
}
