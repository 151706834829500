import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Column,
  Spacer,
  BackboneShelves
} from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { ShelfContent } from '../ShelfContent'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

interface ModalBodyShelvesProps {
  currentPage: number
  data: IUsualsAndFavoritesData
  isLoadingMyUsualsAndFavorites: boolean
  isLoadingOrderForm: boolean
  isMobile?: boolean
  totalPages: number
  handleChancePage: () => void
  handleShowToast: (value: boolean) => void
}

export const ModalBodyShelves = ({
  currentPage,
  data,
  isLoadingMyUsualsAndFavorites,
  isLoadingOrderForm,
  isMobile,
  totalPages,
  handleChancePage,
  handleShowToast
}: ModalBodyShelvesProps): React.ReactElement => {
  const targetRef = useRef(null)
  const isMountedRef = useRef(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const getProducts = () => {
    return data?.products?.map((product) => {
      return { ...product?.item, ...product?.price, quantity: 0 }
    })
  }

  const { handleAdd, handleRemove, handleOnClick } = useUpdateFromShelf({
    products: getProducts(),
    tagProductQuantityChange: true,
    isOrderFormLoading: isLoadingOrderForm,
    getListId: () => {
      return 'modal no te olvides'
    },
    getListName: () => {
      return 'modal no te olvides'
    }
  })

  useEffect(() => {
    isMountedRef.current = true

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (isMountedRef.current) {
          setIsScrolled(entry.isIntersecting)
        }
      },
      {
        root: null, // use the viewport as the root
        threshold: 0.1 // trigger when 10% of the element is in view
      }
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      isMountedRef.current = false
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (
      isScrolled &&
      totalPages > currentPage &&
      !isLoadingMyUsualsAndFavorites
    ) {
      handleChancePage()
    }
  }, [isScrolled])

  return (
    <Container
      gap={isMobile ? 0 : 8}
      id={getBemId('myUsualsAndFavoritesBody', 'shelves')}
      isWrap
    >
      {isValidArrayWithData(data?.products) &&
        data.products.map((product, index) => {
          return (
            <React.Fragment key={product?.item?.itemId}>
              <ShelfContent
                handleAdd={handleAdd}
                handleOnClick={handleOnClick}
                handleRemove={handleRemove}
                handleShowToast={handleShowToast}
                indexItem={index}
                isLoadingOrderForm={isLoadingOrderForm}
                isMobile={isMobile}
                product={product}
              />
            </React.Fragment>
          )
        })}
      <Column ref={targetRef}>
        <Spacer.Horizontal customSize={16} />
        {isLoadingMyUsualsAndFavorites && (
          <BackboneShelves
            noBackground={true}
            shelvesForRow={isMobile ? 2 : 3}
            shelvesQuantityDesktop={3}
            shelvesQuantityMobile={2}
          />
        )}
      </Column>
    </Container>
  )
}
