import { useEffect, useState } from 'react'
import { Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  useMobile,
  useNewCartUnimarc,
  useOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import productsQuantity from 'shared/utils/productsQuantity/productsQuantity'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { getCategoryFromProducts } from 'shared/helpers'
import { ProductsContainer } from './ProductsContainer'

interface ProoductsProps {
  isLoadingByEvent: boolean
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  setLimitOnProductAlert: (value: boolean) => void
}

export const Products = ({
  isLoadingByEvent,
  objetSrcImagesIconAddress,
  setLimitOnProductAlert
}: ProoductsProps): React.ReactElement => {
  const [openCollapse, setOpenCollapse] = useState(false)
  const { data } = useNewCartUnimarc()
  const { data: orderFormData, isLoading: isLoadingOrderForm } = useOrderForm()
  const cartData: ICart = data
  const categories = getCategoryFromProducts(cartData)
  const products = isValidArrayWithData(categories?.items)
    ? categories.items
    : null
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const saveQuantityItems = () => {
    const quantityItems = {}
    if (products) {
      products.forEach(({ description, price }) => {
        quantityItems[description?.id] = price?.unitMultiplier
      })
    }
    productsQuantity.updateProducts(quantityItems)
  }

  const handleCollapse = (open: boolean) => {
    setOpenCollapse(open)
  }

  useEffect(() => {
    saveQuantityItems()
  }, [cartData?.products])

  return (
    <>
      <BigScreen>
        <Column
          id={getBemId('productsList', 'container')}
          isWrap
          margin='0 56px 0 0'
          maxWidth='690px'
          minWidth='690px'
          tagName='section'
        >
          <ProductsContainer
            dataCart={data}
            handleCollapse={handleCollapse}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingOrderForm={isLoadingOrderForm}
            isMobile={isMobile}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            orderFormData={orderFormData}
            setLimitOnProductAlert={setLimitOnProductAlert}
          />
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column
          id={getBemId('productsList', 'container')}
          padding='0 16px'
          tagName='section'
        >
          {data && <Spacer.Horizontal customSize={76} />}
          <ProductsContainer
            dataCart={data}
            handleCollapse={handleCollapse}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingOrderForm={isLoadingOrderForm}
            isMobile={isMobile}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            orderFormData={orderFormData}
            setLimitOnProductAlert={setLimitOnProductAlert}
          />
          <Spacer.Horizontal customSize={160} />
        </Column>
      </SmallScreen>
    </>
  )
}
