import { Row, Column, Spacer } from '@smu-chile/pkg-unimarc-components'

import { Image } from './Image'
import { Name } from './Name'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

interface ProductDetailsProps {
  name: string
  img: string
}

export const ProductDetails = ({
  img,
  name
}: ProductDetailsProps): React.ReactElement => {
  return (
    <Row
      alignItems='center'
      justifyContent='start'
    >
      <Column
        id={getBemId('shelfImage', 'container')}
        justifyContent='start'
        maxWidth='max-content'
      >
        <Image
          altPicture={name}
          srcPicture={img}
        />
      </Column>
      <Spacer.Vertical customSize={30} />
      <Column
        alignItems='start'
        maxWidth='180px'
      >
        <Name name={name} />
      </Column>
    </Row>
  )
}
