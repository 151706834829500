import { useEffect } from 'react'
import {
  DesktopModalWrapper,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalButton } from '../ModalButton'
import { ModalBody } from '../ModalBody'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../MyUsualsAndFavoritesWrapper.module.css'

export interface ModalProps {
  favorites: IUsualsAndFavoritesData
  isLoadingMyUsualsAndFavorites: boolean
  isLoadingOrderForm: boolean
  myUsuals: IUsualsAndFavoritesData
  handleChancePage: () => void
  handleClick: () => void
  handleClose: () => void
}

export const ModalDesktop = ({
  favorites,
  isLoadingMyUsualsAndFavorites,
  isLoadingOrderForm,
  myUsuals,
  handleChancePage,
  handleClick,
  handleClose
}: ModalProps): React.ReactElement => {
  const modal = document.getElementById('myUsualsAndFavoritesModal__container')

  const onClose = async () => {
    if (modal) {
      modal.classList.remove(styles.openModal)
      modal.classList.add(styles.closeModal)
      await sleep(200)
    }
    handleClose()
  }

  useEffect(() => {
    if (modal) {
      modal.classList.add(styles.openModal)
      modal.classList.remove(styles.closeModal)
    }
  }, [modal])
  return (
    <DesktopModalWrapper
      blockId={getBemId('myUsualsAndFavoritesModal', 'header')}
      bodyChildrenProps={{
        maxHeight: 'none',
        overflow: 'auto',
        padding: '0'
      }}
      borderEdge='none'
      footerChildrenProps={{
        boxShadow: '0',
        default: false,
        children: (
          <Container
            customClassName={styles.customBorderTop}
            justifyContent='center'
            padding='16px 32px'
          >
            <ModalButton
              handleOnClick={handleClick}
              isLoadingOrderForm={isLoadingOrderForm}
              minWidth='406px'
            />
          </Container>
        ),
        height: 72,
        padding: '0',
        bottom: '0'
      }}
      headerCloseIcon={{
        name: 'CloseThin',
        customSize: 20,
        default: true
      }}
      headerContainerProps={{
        minHeight: '68px'
      }}
      headerCustomClass={styles.customHeader}
      headerTitle='¡Qué no se te olviden!'
      headerTitleProps={{
        fontWeight: 'semibold',
        default: true
      }}
      modalConfigsProps={{
        containerId: getBemId('myUsualsAndFavoritesModal', 'container'),
        customContainerClassName: styles.modalContainer,
        isOpen: true,
        minHeight: '100vh',
        maxWidth: '656px',
        isWindowBlocked: true,
        toggle: onClose,
        toggleOutside: onClose
      }}
      styleProps={{
        borderRadius: getGlobalStyle('--border-radius-none'),
        customHeightModalContent: '100%'
      }}
    >
      <ModalBody
        favorites={favorites}
        handleChancePage={handleChancePage}
        isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
        isLoadingOrderForm={isLoadingOrderForm}
        myUsuals={myUsuals}
      />
    </DesktopModalWrapper>
  )
}
