import React from 'react'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import styles from './HeadDetail.module.css'

interface HeadDetailProps {
  cartData: ICart
  openCollapse?: boolean
  showIconCollapse: boolean
  onClickCollapse?: () => void
}

const HeadDetail = ({
  cartData,
  openCollapse,
  onClickCollapse,
  showIconCollapse
}: HeadDetailProps) => {
  const { isMobile } = useMobile()

  const allProducts = cartData?.products?.find(({ categoryName }) => {
    return categoryName === 'Todos'
  }) ?? { totals: 0 }
  const totalQuantity = allProducts?.totals

  const padding = isMobile && openCollapse ? '0 0 15px 0' : '0 0 8px 0'
  const iconCollapse = openCollapse ? 'DropDownCloseV2' : 'DropDownOpenV2'
  const paddingContainer = !openCollapse ? '15px 16px 20px' : '15px 16px 0'

  return (
    <Container
      onClick={onClickCollapse}
      style={{
        height: 'auto',
        padding: isMobile ? paddingContainer : '0',
        zIndex: isMobile ? '9' : '0'
      }}
    >
      <Row
        clickable='pointer'
        customClassName={styles.borderBottom}
        padding={isMobile ? padding : '20px 16px 10px'}
        tagName='section'
      >
        <Column justifyContent='between'>
          <Row
            alignItems='center'
            clickable='pointer'
            id={getBemId('titleShelves', 'container')}
          >
            <Column maxWidth='max-content'>
              <Text
                clickable='pointer'
                color='black'
                fontSize='md'
                fontWeight='medium'
              >
                Mis productos 🥑
              </Text>
            </Column>
            <Spacer.Vertical size={4} />
            <Column maxWidth='max-content'>
              <Text
                fontSize='sm'
                fontWeight='regular'
              >
                ({totalQuantity})
              </Text>
            </Column>
          </Row>
        </Column>
        <Column
          id={getBemId('titleShelvesIcon', 'container')}
          maxWidth='max-content'
        >
          {showIconCollapse && (
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-base-black')}
              customSize={24}
              name={iconCollapse}
            />
          )}
        </Column>
      </Row>
    </Container>
  )
}

export default HeadDetail
