import {
  Row,
  Column,
  Container,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import dynamic from 'next/dynamic'
import { Products } from './Products'
import { Totalizers } from './Totalizers'
import { useState } from 'react'
import { BodyLoader } from '../BodyLoader'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { IGetMyUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'

const DynamicOfferYou = dynamic(() => {
  return import('../OfferYou').then((module) => {
    return module.OfferYouCarousel
  })
})

export interface ProductsTotalizersProps {
  cartData: ICart
  createListButton: boolean
  isLoadingByEvent: boolean
  isLoadingCart: boolean
  isLoadingOrderForm: boolean
  notificationAddToList: boolean
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  openMyUsuals: boolean
  selectedListName: string
  handleGetMyUsualsAndFavorites: (
    page?: number
  ) => Promise<IGetMyUsualsAndFavoritesData>
  handleOpenMyListsModal: () => void
  handleOnClosed: () => void
  setNotificationAddToList: (type: boolean) => void
}

export const ProductsTotalizers = ({
  cartData,
  createListButton,
  isLoadingByEvent,
  isLoadingCart,
  isLoadingOrderForm,
  notificationAddToList,
  objetSrcImagesIconAddress,
  openMyUsuals,
  selectedListName,
  handleGetMyUsualsAndFavorites,
  handleOpenMyListsModal,
  handleOnClosed,
  setNotificationAddToList
}: ProductsTotalizersProps): React.ReactElement => {
  const [limitOnProductAlert, setLimitOnProductAlert] = useState(false)

  if (!cartData || isLoadingCart || isLoadingOrderForm) {
    return <BodyLoader />
  }

  return (
    <>
      <BigScreen>
        <Container isWrap>
          <Row tagName='section'>
            <Products
              isLoadingByEvent={isLoadingByEvent}
              objetSrcImagesIconAddress={objetSrcImagesIconAddress}
              setLimitOnProductAlert={setLimitOnProductAlert}
            />
            <Totalizers
              createListButton={createListButton}
              handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
              handleOnClosed={handleOnClosed}
              handleOpenMyListsModal={handleOpenMyListsModal}
              isLoadingByEvent={isLoadingByEvent}
              limitOnProductAlert={limitOnProductAlert}
              notificationAddToList={notificationAddToList}
              openMyUsuals={openMyUsuals}
              selectedListName={selectedListName}
              setLimitOnProductAlert={setLimitOnProductAlert}
              setNotificationAddToList={setNotificationAddToList}
            />
          </Row>
          <Spacer.Horizontal size={12} />
          <Row tagName='section'>
            <DynamicOfferYou />
          </Row>
        </Container>
      </BigScreen>

      <SmallScreen>
        <Column tagName='section'>
          <Row tagName='section'>
            <Products
              isLoadingByEvent={isLoadingByEvent}
              objetSrcImagesIconAddress={objetSrcImagesIconAddress}
              setLimitOnProductAlert={setLimitOnProductAlert}
            />
            <Totalizers
              createListButton={createListButton}
              handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
              handleOnClosed={handleOnClosed}
              handleOpenMyListsModal={handleOpenMyListsModal}
              isLoadingByEvent={isLoadingByEvent}
              limitOnProductAlert={limitOnProductAlert}
              notificationAddToList={notificationAddToList}
              openMyUsuals={openMyUsuals}
              selectedListName={selectedListName}
              setLimitOnProductAlert={setLimitOnProductAlert}
              setNotificationAddToList={setNotificationAddToList}
            />
          </Row>
          <Row
            padding='0px 0px 0px 7.5px'
            tagName='section'
          >
            <DynamicOfferYou />
          </Row>
        </Column>
      </SmallScreen>
    </>
  )
}
