import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import {
  Row,
  Column,
  Carousel,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { AddressSelected } from 'components/AddressSelected'
import { Shelves } from '../Shelves'
import { getCategoryFromProducts } from 'shared/helpers'
import HeadDetail from '../HeadDetail'
import styles from './ProductsContainer.module.css'

interface ProductsContainerProps {
  dataCart: ICart
  isLoadingByEvent: boolean
  isLoadingOrderForm: boolean
  isMobile: boolean
  openCollapse: boolean
  orderFormData: ResponseOrderFormProps
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  handleCollapse: (open: boolean) => void
  setLimitOnProductAlert: (value: boolean) => void
}

export const ProductsContainer = ({
  dataCart,
  isLoadingByEvent,
  isLoadingOrderForm,
  isMobile,
  openCollapse,
  orderFormData,
  objetSrcImagesIconAddress,
  handleCollapse,
  setLimitOnProductAlert
}: ProductsContainerProps): React.ReactElement => {
  const products =
    isValidArrayWithData(dataCart?.products) &&
    getCategoryFromProducts(dataCart)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: products?.items?.length,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: products?.items?.length,
      slidesToSlide: 1
    }
  }
  return (
    <>
      {!isLoadingOrderForm && orderFormData?.data?.selectedAddresses && (
        <AddressSelected
          isLoading={isLoadingByEvent}
          isMobile={isMobile}
          objetSrcImagesIconAddress={objetSrcImagesIconAddress}
          selectedAddress={orderFormData?.data?.selectedAddresses}
        />
      )}
      <Row
        backgroundColor={getGlobalStyle('--color-base-white')}
        border={
          openCollapse &&
          `0.6px solid ${getGlobalStyle('--color-neutral-gray-divider')}`
        }
        borderRadius={getGlobalStyle('--border-radius-md')}
        tagName='section'
      >
        <Column>
          <HeadDetail
            cartData={dataCart}
            onClickCollapse={() => {
              return handleCollapse(!openCollapse)
            }}
            openCollapse={openCollapse}
            showIconCollapse
          />
          <Row tagName='section'>
            <Column
              id={getBemId('products', 'container')}
              tagName='section'
            >
              {openCollapse && (
                <Carousel
                  arrows={false}
                  autoPlay={false}
                  containerClass={styles['wrapper__carouselContainer']}
                  containerProps={{
                    borderRadius: 'initial',
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                  infinite={false}
                  partialVisbile
                  responsive={responsive}
                  shouldResetAutoplay={false}
                  showDots={false}
                  ssr
                >
                  {isValidArrayWithData(products?.items) &&
                    products?.items.map((item, index) => {
                      return (
                        <Picture
                          alt={item?.description?.name}
                          height={isMobile ? '50px' : '80px'}
                          id={getBemId('product', 'image', index.toString())}
                          key={item?.description?.id}
                          objectFit='contain'
                          src={item?.description?.image}
                          width={isMobile ? '50px' : '80px'}
                        />
                      )
                    })}
                </Carousel>
              )}
              {!openCollapse && (
                <Shelves
                  cartData={dataCart}
                  isLoadingByEvent={isLoadingByEvent}
                  setLimitOnProductAlert={setLimitOnProductAlert}
                />
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  )
}
