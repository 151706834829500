import { Text, Column } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import style from './Name.module.css'

export interface NameProps {
  name: string
}

export const Name = ({ name }: NameProps): React.ReactElement<NameProps> => {
  return (
    <>
      <BigScreen>
        <Column customWidth='100'>
          <Text
            customClassName={style.productName__name}
            fontSize='lg'
            id={getBemId('shelfProductName', 'text')}
            lineClamp={2}
            truncate={35}
          >
            {name}
          </Text>
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column>
          <Text
            customClassName={style.productName__name}
            fontSize='sm'
            id={getBemId('shelfProductName', 'text')}
            lineClamp={2}
            truncate={45}
          >
            {name}
          </Text>
        </Column>
      </SmallScreen>
    </>
  )
}
